import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Heading from "@coninsa-s2/heading";
import NumericCard from "@coninsa-s2/numeric-card";

import data from "./data";

export default function MoreInfo() {
  const sliderOptions = {
    arrowPath:
      "M9.392,28.344h21.7l-7.467,7.467a1.392,1.392,0,1,0,1.969,1.968l9.843-9.843h0a1.4,1.4,0,0,0,.173-.212c.023-.035.04-.073.06-.11a1.394,1.394,0,0,0,.068-.13,1.238,1.238,0,0,0,.045-.144c.011-.039.026-.076.034-.115a1.391,1.391,0,0,0,.027-.273h0s0,0,0,0a1.4,1.4,0,0,0-.027-.269c-.008-.042-.024-.08-.036-.121a1.292,1.292,0,0,0-.043-.138,1.441,1.441,0,0,0-.072-.138c-.019-.034-.034-.069-.055-.1a1.371,1.371,0,0,0-.174-.214L25.59,16.124a1.392,1.392,0,1,0-1.969,1.969l7.467,7.467H9.392a1.392,1.392,0,1,0,0,2.784Z",
    arrows: true,
    gap: "1rem",
    perPage: 2,
    perMove: 1,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: "2rem",
        perPage: 1,
        padding: { left: 0, right: "3rem" },
        pagination: true,
      },
    },
  };

  return (
    <Section ptop="small">
      <Container>
        <div className="text-center">
          <Heading size="4xl" tint="dark-blue" className="!mb-6" condensed>
            <span className="text-s2-teal font-extrabold block">
              Recuerda tener a la mano
            </span>
            <span className="text-4xl">esta información</span>
          </Heading>
        </div>
        <Splide options={sliderOptions} className="s2-slider s2-slider--lg">
          {data.map((item) => (
            <SplideSlide>
              <div className="px-2 pt-2 pb-4">
                <NumericCard tint="lime" class="s2-numeric-card">
                  <div class="s2-numeric-card__number">{item.label}</div>
                  <div class="s2-numeric-card__content">
                    <h4 class="s2-numeric-card__title">{item.title}</h4>
                    <p>{item.description}</p>
                  </div>
                </NumericCard>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </Container>
    </Section>
  );
}
