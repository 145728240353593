import React from "react";

import Banner from "./src/banner";
import OurBenefits from "./src/our-benefits";
import OurRates from "./src/our-rates";
import ContactForm from "./src/contact-form";
import MoreInfo from "./src/more-info";

function Consignment() {
  return (
    <div>
      <Banner />
      <OurBenefits />
      <OurRates />
      <MoreInfo />
      <ContactForm />
    </div>
  )
}

export default Consignment;
