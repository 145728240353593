export default [
  {
    id: "commerce-1",
    title: "Premium",
    tag: "Tarifa del",
    tint: "lime",
    rate: "10%",
    text: "Fecha de pago el quinto día hábil del mes."
  },
  {
    id: "commerce-2",
    title: "Estándar",
    tag: "Tarifa del",
    tint: "teal",
    rate: "9%",
    text: "Fecha de pago el décimo día hábil del mes."
  },
  {
    id: "commerce-3",
    title: "Básica",
    tag: "Tarifa del",
    tint: "teal",
    rate: "8%",
    text: "Fecha de pago al final del mes."
  }
]
