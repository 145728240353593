import React from 'react';
import clsx from "clsx";

import "./styles.css";

interface SimpleCardProps {
  icon?: string;
  children?: React.ReactNode;

  className?: string;
}

const SimpleCard: React.FC<SimpleCardProps> = ({
  icon = null,
  children,

  className,
  ...props
}) => {
  const dynamicClasses = {
  };

  return (
    <div className={clsx("s2-simple-card", dynamicClasses, className)} {...props}>
        <div className="s2-simple-card__icon">
          <img src={icon} />
        </div>
      <div className="s2-simple-card__content">{children}</div>
    </div>
  );
}

export default SimpleCard;
