import React from "react";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import * as Accordion from "@coninsa-s2/accordion";

export default function ForAbrUsers({ className }) {
  return (
    <Section tint="light-gray" className={className}>
      <Container>
        <div className="mb-6 lg:mb-8">
          <h3 className="mb-1 font-display text-2xl text-s2-dark-blue lg:text-3xl">
            Si eres usuario de Arrendamientos
          </h3>
          <p className="text-sm text-s2-gray-600 lg:text-base">
            Entérate de lo que puedes hacer a través de Mi Coninsa Virtual
          </p>
        </div>
        <Accordion.Root type="single" defaultValue="item-1" collapsible>
          <Accordion.Item value="item-1">
            <Accordion.Trigger>
              <div className="flex flex-col items-start">
                <div className="text-sm text-s2-gray-600 lg:text-base">
                  Si tu perfil es
                </div>
                <p className="text-left font-bold text-s2-teal lg:text-lg">
                  Arrendatario y/o Coarrendatario
                </p>
              </div>
            </Accordion.Trigger>
            <Accordion.Content>
              <ul className="-mx-5 list-inside list-disc text-sm marker:text-s2-lime lg:text-base">
                <li className="py-2 text-s2-gray-600 px-8">
                  <span className="font-bold">Contratos asociados:</span>
                  <p className="px-6">
                    Podrás consultar el tipo y estado de tus contratos, código,
                    fecha de inicio, de terminación, canon de arrendamiento, y
                    muchos otros datos útiles como las facturas de canon
                    pendientes y el valor a pagar.
                  </p>
                </li>
                <li className="px-8 py-2 text-s2-gray-600">
                  <span className="font-bold">Certificados y constancias:</span>
                  <p className="px-6">
                    Podrás visualizar, descargar, imprimir o enviar por correo
                    las constancia que te certifica como arrendatario de un
                    inmueble que administra nuestra Compañía.
                  </p>
                </li>
                <li className="px-8 py-2 text-s2-gray-600">
                  <span className="font-bold">Historial de facturas:</span>
                  <p className="px-6">
                    podrás visualizar o descargar las facturas de canon de los
                    últimos 7 meses.
                  </p>
                </li>
              </ul>
            </Accordion.Content>
          </Accordion.Item>

          <Accordion.Item value="item-2">
            <Accordion.Trigger>
              <div className="flex flex-col items-start">
                <div className="text-sm text-s2-gray-600 lg:text-base">
                  Si tu perfil es
                </div>
                <p className="font-bold text-s2-teal lg:text-lg">
                  Propietario y/o Apoderado
                </p>
              </div>
            </Accordion.Trigger>
            <Accordion.Content>
              <ul className="-mx-5 list-inside list-disc text-sm marker:text-s2-lime lg:text-base">
                <li className="px-8 py-2 text-s2-gray-600">
                  <span className="font-bold">Contratos asociados:</span>
                  <p className="px-6">
                    Podrás consultar el tipo y estado de tus contratos, código,
                    fecha de inicio, de terminación, canon de arrendamiento, y
                    muchos otros datos útiles como las facturas de canon
                    pendientes y el valor a pagar.
                  </p>
                </li>
                <li className="px-8 py-2 text-s2-gray-600">
                  <span className="font-bold">Propiedades:</span>
                  <p className="px-6">
                    Podrás consultar el tipo y estado de tus contratos, código,
                    fecha de inicio, de terminación, día de pago, canon de
                    arrendamiento, meses de garantía, tarifa, y muchos otros
                    datos útiles como las facturas de comisión pendientes y el
                    valor a pagar.
                  </p>
                </li>
                <li className="px-8 py-2 text-s2-gray-600">
                  <span className="font-bold">Historial de facturas:</span>
                  <p className="px-6">
                    Podrás visualizar o descargar las facturas de comisión de
                    los últimos meses. Al igual que tus certificados de ingresos
                    y retenciones del año anterior.
                  </p>
                </li>
                <li className="px-8 py-2 text-s2-gray-600">
                  <span className="font-bold">Constancias:</span>
                  <p className="mb-2 px-6">
                    Podrás visualizar o descargar las constancias que te
                    certifican como propietario y/o beneficiario tributario de
                    un inmueble que administra nuestra Compañía.
                  </p>
                  <p className="mb-2 px-6">
                    Después del 7° día de cada mes, podrás revisar el estado de
                    tu cuenta del periodo actual y el los tres anteriores a
                    este, e incluso descargarlo.
                  </p>
                  <p className="px-6">
                    Si tienes tu contrato vigente, no se habilita el botón de
                    pago, ya que los valores a pagar se descuentan
                    automáticamente de la renta mensual que te giramos. Por
                    ende, solo se habilitará el botón cuando tu contrato esté en
                    estado terminado y hayas quedado debiendo algún valor.
                  </p>
                </li>
                <li className="px-8 py-2 text-s2-gray-600">
                  <span className="font-bold">
                    Pagar facturas arrendatario o propietario:
                  </span>
                  <p className="mb-2 px-6">
                    Podrás visualizar el valor a pagar y el botón pagar y
                    redireccionarte a la pasarela de pagos.
                  </p>
                  <p className="mb-2 px-6">
                    Ten en cuenta que si tu contrato está en estado lanzamiento
                    (restitución del inmueble por vías legales) o siniestrado
                    (reportado ante la entidad aseguradora o afianzadora),
                    podrás visualizar la factura, pero no podrás pagar a través
                    de este medio, sino que lo deberás hacer a través del
                    juzgado en la que se encuentre el proceso o la aseguradora o
                    afianzadora por la cual se encuentre tu contrato respaldado.
                  </p>
                  <p className="mb-2 px-6">
                    Al hacer clic en pagar esta factura te saldrá un mensaje
                    como este: “A partir de este momento saldrás del sistema Mi
                    Coninsa Virtual y continuarás en la Pasarela de Pagos de
                    Zona Virtual. Recuerda que para realizar el pago en el
                    Portal Web de tu Banco, debes tener la segunda clave o clave
                    virtual. Si tienes dudas con tu segunda clave, debes
                    comunicarte con tu Entidad Bancaria. Si realizas el pago
                    fuera del horario establecido por tu Banco, éste se verá
                    reflejado el día siguiente”.
                  </p>
                  <p className="mb-2 px-6">
                    En Zona de Pagos verás la información general del pago que
                    vas a realizar y deberás escoger el banco a través del cual
                    vas a realizar la transacción, aceptar los Términos y
                    Condiciones de Zona de Pagos y luego deberás dar clic en el
                    botón Continuar.
                  </p>
                  <p className="mb-2 px-6">
                    Si es la primera vez que usas PSE, te pedirá que hagas un
                    registro sencillo antes de enviarte al banco que hayas
                    seleccionado.
                  </p>
                  <p className="mb-2 px-6">
                    Después de terminar el pago en tu banco, éste te retornará a
                    una página de resultado de Zona de Pagos en el que podrás
                    ver el resultado de la transacción, regresar a Mi Coninsa
                    Virtual o cerrar la ventana.
                  </p>
                  <p className="mb-2 px-6">
                    Ten en cuenta que la página de resultado que te muestra Zona
                    de Pagos, es un comprobante de la transacción que realizaste
                    y que se encuentra sujeta a verificación por nuestra área de
                    Tesorería.
                  </p>
                  <p className="mb-2 px-6">
                    Si retornas a Mi Coninsa Virtual, también te presentaremos
                    un resumen de tu transacción que podrás descargar, imprimir
                    o enviar por correo.
                  </p>
                  <p className="mb-2 px-6">
                    Si en algún momento se presentan fallas en tu conexión de
                    internet, en tu navegador o computador o excedes el tiempo
                    de inactividad, deberás reiniciar el proceso de pago.
                  </p>
                  <p className="mb-2 px-6">
                    Si ya habías intentado un pago a través de Zona de Pagos, te
                    informaremos que hay un error al verificar el estado de tu
                    pago y te pediremos que esperes un momento y vuelvas a
                    intentarlo.
                  </p>
                  <p className="px-6">
                    Te sugerimos que si alguno de estos errores se presenta
                    cuando estas en la plataforma de tu banco, verifiques en
                    esta misma que el pago no se haya realizado, antes de
                    intentar nuevamente en Mi Coninsa Virtual.
                  </p>
                </li>
              </ul>
            </Accordion.Content>
          </Accordion.Item>

          <Accordion.Item value="item-3">
            <Accordion.Trigger>
              <div className="flex flex-col items-start text-left">
                <div className="text-sm text-s2-gray-600 lg:text-base">
                  Si tu perfil es
                </div>
                <p className="font-bold text-s2-teal lg:text-lg">
                  Administrador de Propiedad Horizontal
                </p>
              </div>
            </Accordion.Trigger>
            <Accordion.Content>
              <div className="text-sm lg:text-base">
                <p className="text-s2-gray-600">
                  Para ingresar a Mi Coninsa Virtual, recuerda que debes de
                  ingresar con el NIT de la copropiedad y contraseña escogida.
                </p>
                <ul className="-mx-5 list-inside list-disc marker:text-s2-lime">
                  <li className="px-8 py-2 text-s2-gray-600">
                    <span className="font-bold">Estado de cuenta:</span>
                    <p className="px-6">
                      Podrás visualizar o descargar los últimos tres estados de
                      cuenta de los últimos tres meses de la copropiedad que
                      administras.
                    </p>
                  </li>
                </ul>
              </div>
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>
      </Container>
    </Section>
  );
}
