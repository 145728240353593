import React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import CloseIcon from "./assets/close.inline.svg";
import { cn } from "@coninsa-s2/utils";

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "z-20 fixed inset-0 bg-white opacity-60 data-[state=open]:animate-in data-[state=open]:fade-in data-[state=closed]:animate-out data-[state=closed]:fade-out",
      className
    )}
    {...props}
  />
));
DialogOverlay.displayName = "Dialog.Overlay";

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    variant?: "default" | "left-entry";
  }
>(({ children, className, variant = "default", ...props }, ref) => (
  <DialogPrimitive.Content
    ref={ref}
    className={cn(
      "z-50 fixed shadow-lg p-6 bg-white",
      "lg:left-[50%] lg:top-[50%] w-full max-w-3xl lg:translate-x-[-50%] lg:translate-y-[-50%] border",
      "bottom-0 right-0 top-0 left-0 overflow-y-auto",
      className
    )}
    {...props}
  >
    {children}

    <DialogPrimitive.Close asChild>
      <button
        className="absolute right-6 top-6"
        aria-label="Cerrar"
        type="button"
      >
        <CloseIcon />
      </button>
    </DialogPrimitive.Close>
  </DialogPrimitive.Content>
));
DialogContent.displayName = "Dialog.Content";

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      "relative -mx-6 -mt-6 mb-6 border-b border-slate-200 p-6",
      className
    )}
    {...props}
  />
));
DialogTitle.displayName = "Dialog.Title";

export {
  Dialog as Root,
  DialogTrigger as Trigger,
  DialogClose as Close,
  DialogPortal as Portal,
  DialogOverlay as Overlay,
  DialogContent as Content,
  DialogTitle as Title,
};
