import React from "react"

import Layout from "../../layout/default/Layout"
import Consignment from '@coninsa-site/consignment';

export default function Landing() {
  return (
    <Layout>
      <Consignment />
    </Layout>
  )
}
