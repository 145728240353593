import React from "react";
import { useState } from "react";
import * as Dialog from "@coninsa-s2/dialog";

import PromoCard from "@coninsa-s2/promo-card";
import presentHolding from "../../assets/icons/present-holding.svg";

export default function CommerceModal() {
  const [open, setOpen] = useState(false);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <div className="flex justify-center">
          <button>
            <PromoCard>
              <div className="s2-promo-card__icon">
                <img src={presentHolding} />
              </div>
              <div>
                <span className="s2-promo-card__tag">
                  Complementa tus servicios con nuestros
                </span>
                <h4 className="s2-promo-card__title">Amparos adicionales</h4>
                <p className="s2-promo-card__text">
                  Conoce los de vivienda aquí
                </p>
              </div>
            </PromoCard>
          </button>
        </div>
      </Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="max-w-4xl rounded-lg" tabIndex={undefined}>
          <Dialog.Title asChild>
            <h4 className="font-bold text-s2-dark-blue lg:text-lg">
              Amparos adicionales comercio
            </h4>
          </Dialog.Title>
          <div>
            <div className="mb-4">
              <p className="mb-1 text-xs text-s2-gray-600">
                Todos los amparos aplican para contratos de 12 meses.
              </p>
              <p className="text-xs text-s2-gray-600">
                *Aplican términos y condiciones. Conócelos en{" "}
                <a
                  href="https://www.coninsa.co/terminos-y-condiciones-concursos-y-promociones"
                  target="_blank"
                  className="text-s2-blue"
                >
                  www.coninsa.co/terminos
                </a>
              </p>
            </div>
            <div>
              <ul className="list-inside list-disc text-sm text-s2-gray-600 marker:text-s2-lime lg:text-base">
                <div className="mb-4">
                  <li className="font-bold text-s2-lime">
                    Amparo de servicios públicos
                  </li>
                  <div className="ml-5">
                    <p className="mb-3">
                      <span className="block font-bold">
                        Súmale solo el 0.4%
                      </span>{" "}
                      súper premium: 11.4% premium: 11.2% estándar: 10.9%
                      básica: 10.4%
                    </p>
                    <p>
                      Cubre <strong>hasta $1.000.000</strong> por concepto de
                      facturas de servicios domiciliarios dejados de pagar por
                      tu ex arrendatario.
                    </p>
                  </div>
                </div>
                <div>
                  <li className="font-bold text-s2-lime">
                    Amparo de daños y faltantes
                  </li>
                  <div className="ml-5">
                    <p className="mb-3">
                      <span className="block font-bold">
                        Súmale solo el 0.6%{" "}
                      </span>{" "}
                      súper premium: 12% premium: 11.8% estándar: 11.5% básica:
                      11%
                    </p>
                    <p>
                      Cubre hasta $1.000.000 por concepto de daños y faltantes
                      dejados por tu arrendatario al final del contrato.
                    </p>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
