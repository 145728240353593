import React from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import "react-intl-tel-input-18/dist/main.css";
import parsePhoneNumber from "libphonenumber-js";
import IntlPhone from "@coninsa-s2/intl-phone";

import Button from "@coninsa-s2/button";
import HabeasData from "../../../../react/quotation/src/components/HabeasData";

import cities from "../../data/cities.json";

function ReferrerForm() {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm({ defaultValues: { phone: "", refPhone: "" } });

  const onSubmit = async (data) => {
    const zohoInfo = {
      data: [
        {
          First_Name: data.referred_name,
          Last_Name: data.referred_last_name,
          Owner: { id: "2528071000008997151" },
          Email: data.referred_email,
          Observaciones_del_cliente: `${data.referrer_name} ${data.referrer_last_name}, ${data.referrer_email}, ${data.refPhone}`,
          Mobile: data.phone,
          Sucursal: "ABR",
          Regional: "Colombia",
          Origen_de_Informaci_n: "Digital",
          Medio_Publicitario: "Referidos",
          Creador_API: "www.coninsa.co",
          Tipo_de_Canal: "Digital",
          Tipo_de_Contacto: "Otros",
          Layout: {
            id: "2528071000003925032",
          },
          Servicio_interes: data.service_interest,
        },
      ],
    };

    const url = "https://api.coninsa.co/api/v2/zohocrm-create-lead";

    return new Promise((resolve, reject) => {
      axios
        .post(url, zohoInfo)
        .then((response) => {
          reset();
          resolve(response);
          window.open("https://www.coninsa.co/gracias");
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  return (
    <div>
      <div className="form pt-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-container container">
          <div className="form-header">
            <i className="co-icon icon-team"></i>
            <h2 className="form-title">
              Diligencia el formulario{" "}
              <span className="is-not-bold">
                refiere un amigo o familiar y gana beneficios
              </span>
            </h2>
          </div>
          <form className="form-subject-matter md:w-3/4">
            <div className="form-content">
              <div className="title">
                <div className="circle">1</div>
                <h3 className="information">Tus datos</h3>
              </div>

              <div className="co-form">
                <div className="form-control">
                  <input
                    {...register("referrer_name")}
                    type="text"
                    id="referrer_name"
                    placeholder="Nombres"
                    required
                  />
                </div>
                <div className="form-control">
                  <input
                    {...register("referrer_last_name")}
                    type="text"
                    id="referrer_last_name"
                    placeholder="Apellidos"
                    required
                  />
                </div>
                <div className="form-control is-intl-tel">
                  <Controller
                    control={control}
                    name="refPhone"
                    rules={{
                      validate: (value) =>
                        parsePhoneNumber(value)?.isValid() ||
                        "Número de teléfono inválido",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <IntlPhone
                        id="referrer_phone"
                        placeholder="Número de contacto"
                        defaultCountry={"co"}
                        onChange={onChange}
                        required
                        value={value}
                      />
                    )}
                  />

                  {errors?.refPhone && (
                    <p className="form-error">{errors?.refPhone?.message}</p>
                  )}
                </div>
                <div className="form-control">
                  <input
                    {...register("referrer_email")}
                    type="email"
                    id="referrer_email"
                    placeholder="Tu correo electrónico"
                    required
                  />
                </div>
                <div className="form-control">
                  <select
                    {...register("referrer_country")}
                    className="text-area"
                    id="referrer_country"
                    required
                  >
                    <option value="">Ciudad de residencia</option>
                    {cities.map((city) => (
                      <option key={city.value} value={city.value}>
                        {city.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="form-content">
              <div className="title">
                <div className="circle">2</div>
                <h3 className="information">Datos de tu referido</h3>
              </div>

              <div className="co-form">
                <div className="form-control">
                  <input
                    {...register("referred_name")}
                    type="text"
                    id="referred_name"
                    placeholder="Nombres"
                    required
                  />
                </div>
                <div className="form-control">
                  <input
                    {...register("referred_last_name")}
                    type="text"
                    id="referred_last_name"
                    placeholder="Apellidos"
                    required
                  />
                </div>
                <div className="form-control is-intl-tel">
                  <Controller
                    control={control}
                    name="phone"
                    rules={{
                      validate: (value) =>
                        parsePhoneNumber(value)?.isValid() ||
                        "Número de teléfono inválido",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <IntlPhone
                        id="phone"
                        placeholder="Número de contacto"
                        defaultCountry={"co"}
                        onChange={onChange}
                        required
                        value={value}
                      />
                    )}
                  />

                  {errors?.phone && (
                    <p className="form-error">{errors?.phone?.message}</p>
                  )}
                </div>
                <div className="form-control">
                  <input
                    {...register("referred_email")}
                    type="email"
                    id="referred_email"
                    placeholder="Tu correo electrónico"
                    required
                  />
                </div>

                <div className="form-control">
                  <select
                    {...register("referred_country")}
                    className="text-area"
                    id="referred_country"
                    required
                  >
                    <option value="">Ciudad de residencia</option>
                    {cities.map((city) => (
                      <option key={city.value} value={city.value}>
                        {city.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-control">
                  <select
                    className="text-area"
                    {...register("service_interest")}
                    id="service_interest"
                    required
                  >
                    <option value="">Servicio de interés</option>
                    <option value="Venta">Venta</option>
                    <option value="Arriendo">Arriendo</option>
                  </select>
                </div>

                <HabeasData />

                <div className="form-control is-checkbox">
                  <input type="checkbox" id="data-policy" required />
                  <label htmlFor="data-policy" className="data-policy">
                    Autorizo el tratamiento y la{" "}
                    <a
                      className="co-link is-primary"
                      href="https://www.coninsa.co/politica-de-tratamiento-de-datos-personales-de-coninsa-ramon-h-sa"
                      target="_blank"
                    >
                      Política de datos personales
                    </a>
                  </label>
                </div>
                <Button disabled={isSubmitting}>
                  {!isSubmitting ? "Enviar mi referido" : "Enviando ..."}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ReferrerForm;
