import React from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@coninsa-s2/slider/styles.css";

import Section from "@coninsa-s2/section";
import Container from "@coninsa-s2/container";
import Heading from "@coninsa-s2/heading";
import RateCard from "@coninsa-s2/rate-card";

import DwellingModal from "./dwelling-modal";
import CommerceModal from "./commerce-modal";

import dwellingData from "./dwelling-data";
import commerceData from "./commerce-data";

export default function OurRates() {
  const sliderOptions = {
    arrowPath:
      "M9.392,28.344h21.7l-7.467,7.467a1.392,1.392,0,1,0,1.969,1.968l9.843-9.843h0a1.4,1.4,0,0,0,.173-.212c.023-.035.04-.073.06-.11a1.394,1.394,0,0,0,.068-.13,1.238,1.238,0,0,0,.045-.144c.011-.039.026-.076.034-.115a1.391,1.391,0,0,0,.027-.273h0s0,0,0,0a1.4,1.4,0,0,0-.027-.269c-.008-.042-.024-.08-.036-.121a1.292,1.292,0,0,0-.043-.138,1.441,1.441,0,0,0-.072-.138c-.019-.034-.034-.069-.055-.1a1.371,1.371,0,0,0-.174-.214L25.59,16.124a1.392,1.392,0,1,0-1.969,1.969l7.467,7.467H9.392a1.392,1.392,0,1,0,0,2.784Z",
    arrows: false,
    gap: "2.5rem",
    perPage: 4,
    perMove: 1,
    pagination: false,
    breakpoints: {
      640: {
        arrows: false,
        gap: "2rem",
        perPage: 1,
        padding: { left: 0, right: "11rem" },
        pagination: true,
      },
    },
  };

  return (
    <Section ptop="small">
      <Container>
        <div className="mb-7 lg:mb-10">
          <Heading
            size="4xl"
            tint="teal"
            condensed
            className="!mb-4 text-center !font-medium"
          >
            Conoce nuestras{" "}
            <span className="ml-2 font-handwritting text-3xl text-s2-dark-blue lg:ml-5 lg:text-7xl">
              {" "}
              tarifas
            </span>
          </Heading>
          <p className="mx-auto rounded-md bg-s2-gray-50 p-5 text-sm text-s2-gray-600 lg:w-[768px]">
            <span className="block font-bold">
              Todas las tarifas se facturarán más IVA.
            </span>{" "}
            El tiempo de la garantía del canon se define en el momento del
            estudio del cliente arrendatario por parte de la aseguradora, puede
            cubrir por mora de 6 a 36 meses.
          </p>
        </div>
        <div className="mb-9 lg:mb-10">
          <div className="mb-8">
            <Heading
              size="4xl"
              tint="blue"
              condensed
              className="!mb-4 text-center !font-medium uppercase"
            >
              Vivienda
            </Heading>
            <p className="text-center text-s2-gray-600 lg:text-xl">
              Apartamentos, apartaestudios, casas y fincas en Antioquia, Bogotá
              y Barranquilla.
            </p>
          </div>

          <div className="mb-8 hidden justify-center gap-4 lg:flex">
            {dwellingData.map((item) => (
              <RateCard
                key={item.id}
                title={item.title}
                tag={item.tag}
                rate={item.rate}
                tint={item.tint}
                className="w-48"
              >
                <p>{item.text}</p>
              </RateCard>
            ))}
          </div>
          <div className="lg:hidden">
            <Splide
              options={sliderOptions}
              className="s2-slider s2-slider--light my-4"
            >
              {dwellingData.map((item) => (
                <SplideSlide className="pb-10">
                  <RateCard
                    key={item.id}
                    title={item.title}
                    tag={item.tag}
                    rate={item.rate}
                    tint={item.tint}
                    className="h-52 w-48"
                  >
                    <p>{item.text}</p>
                  </RateCard>
                </SplideSlide>
              ))}
            </Splide>
          </div>
          <DwellingModal />
        </div>
        <div>
          <div className="mb-8">
            <Heading
              size="4xl"
              tint="blue"
              condensed
              className="mb-5 text-center !font-medium uppercase"
            >
              Comercio
            </Heading>
            <p className="text-center text-s2-gray-600 lg:text-xl">
              Apartamentos, apartaestudios, casas y fincas en Antioquia, Bogotá
              y Barranquilla.
            </p>
          </div>

          <div className="mb-8 hidden justify-center gap-4 lg:flex">
            {commerceData.map((item) => (
              <RateCard
                key={item.id}
                title={item.title}
                tag={item.tag}
                rate={item.rate}
                tint={item.tint}
                className="w-[190px]"
              >
                <p>{item.text}</p>
              </RateCard>
            ))}
          </div>
          <div className="lg:hidden">
            <Splide
              options={sliderOptions}
              className="s2-slider s2-slider--light my-4"
            >
              {commerceData.map((item) => (
                <SplideSlide className="pb-10">
                  <RateCard
                    key={item.id}
                    title={item.title}
                    tag={item.tag}
                    rate={item.rate}
                    tint={item.tint}
                    className="h-52 w-48"
                  >
                    <p>{item.text}</p>
                  </RateCard>
                </SplideSlide>
              ))}
            </Splide>
          </div>
          <CommerceModal />
        </div>
      </Container>
    </Section>
  );
}
