import React from "react";
import clsx from "clsx";
import "./styles.css";

interface NumericCardProps {
  tint?: "dark-blue" | "lime";
  children: React.ReactNode;
  className?: string;
}

const NumberCard: React.FC<NumericCardProps> = ({
  children,
  className,
  tint = "lime",
  ...props
}) => {
  const dynamicClasses = {
    [`s2-numeric-card--${tint}`]: tint,
  }

  return (
    <div className={clsx("s2-numeric-card", dynamicClasses, className)} {...props}>
      {children}
    </div>
  )
}

export default NumberCard;
