import keysIcon from "./../../assets/icons/give-keys.svg";
import megaphoneIcon from "./../../assets/icons/megaphone.svg";
import calendarIcon from "./../../assets/icons/calendar.svg";
import settingsIcon from "./../../assets/icons/settings.svg";
import handHoldingIcon from "./../../assets/icons/hand-holding.svg";
import contractIcon from "./../../assets/icons/contract.svg";
import handThumbsIcon from "./../../assets/icons/hand-thumbs.svg";
import tagIcon from "./../../assets/icons/tag.svg";

export default [
  {
    id: "benfit-1",
    icon: keysIcon,
    title: "Analizamos el arrendatario",
    description: `Basados en su <strong>historial crediticio</strong>, capacidad
      de <strong>ingresos</strong> e investigación interna.`,
  },
  {
    id: "benfit-2",
    icon: megaphoneIcon,
    title: "Promocionamos tus inmuebles",
    description: `Publicamos en nuestro portal <strong>www.coninsa.co</strong>,
      redes sociales, portales de aliados, material publicitario como vallas y avisos.`,
  },
  {
    id: "benfit-3",
    icon: calendarIcon,
    title: "Te giramos la renta cada mes",
    description: `Garantizamos tu <strong>pago puntual</strong> de tu arriendo.
      Si el inquilino no paga, <strong>nosotros pagamos</strong>.`,
  },
  {
    id: "benfit-4",
    icon: settingsIcon,
    title: "Administras tus contratos en línea",
    description: `En <strong>Mi Coninsa Virtual y Coninsa Chatbot</strong> puedes
      realizar consultas y transacciones relacionadas a tu inmueble.`,
  },
  {
    id: "benfit-5",
    icon: handHoldingIcon,
    title: "Adelanta tu renta",
    description: `Te giramos la <strong>renta por adelantado</strong> así no se haya
      arrendado tu inmueble. <span class="block text-xs">*Aplican términos y condiciones</span>`,
  },
  {
    id: "benfit-6",
    icon: contractIcon,
    title: "Firma tu contrato desde cualquier lugar",
    description: `Nuestros procesos son digitales con <strong>firma electrónica</strong>.`,
  },
  {
    id: "benfit-7",
    icon: handThumbsIcon,
    title: "Equipos especializados para tus requerimientos",
    description: `Tenemos un <strong>equipo de mantenimiento</strong> para atender
      los inconvenientes de tu inmueble arrendado o en el que vives.`,
  },
  {
    id: "benfit-8",
    icon: tagIcon,
    title: "Amparos adicionales para tu tranquilidad",
    description: `Protege tu propiedad con <strong>extras para tener al día tu inmueble
      </strong> en servicios públicos, daños, vacancias o bolsas de mantenimiento.`,
  }
]
