export default [
  {
    id: "dwelling-1",
    title: "Súper Premium",
    tag: "Tarifa del",
    tint: "mint",
    rate: "11%",
    text: "Fecha de pago el primer día día habil del mes."
  },
  {
    id: "dwelling-2",
    title: "Premium",
    tag: "Tarifa del",
    tint: "blue",
    rate: "10.8%",
    text: "Fecha de pago el quinto día hábil del mes."
  },
  {
    id: "dwelling-3",
    title: "Estándar",
    tag: "Tarifa del",
    tint: "blue",
    rate: "10.5%",
    text: "Fecha de pago el décimo día hábil del mes."
  },
  {
    id: "dwelling-4",
    title: "Básica",
    tag: "Tarifa del",
    tint: "blue",
    rate: "10%",
    text: "Fecha de pago al final del mes."
  }
]
