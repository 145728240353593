import React from 'react';
import clsx from "clsx";

import "./styles.css";

interface IconPromoProps {
  icon?: string;
  children?: React.ReactNode;

  className?: string;
}

const IconPromo: React.FC<IconPromoProps> = ({
  icon = null,
  children,

  className,
  ...props
}) => {
  const dynamicClasses = {
  };

  return (
    <div className={clsx("s2-promo-card", dynamicClasses, className)} {...props}>
      <div className="s2-promo-card__content">{children}</div>
    </div>
  );
}

export default IconPromo;
