import React from "react";

import Banner from "@coninsa-s2/banner";
import Container from "@coninsa-s2/container";
import DialogBox from "@coninsa-s2/dialog-box";
import Button from "@coninsa-s2/button";

import consignmentImage from "../assets/images/banner.png";
import consignmentMobileImage from "../assets/images/mobile-banner.png";

export default function ConsignmentBanner() {
  return (
    <div>
      <Banner
        imageUrl={consignmentImage}
        imageUrlMobile={consignmentMobileImage}
        className="flex items-start lg:items-center lg:pl-16"
      >
        <Container>
          <div className="text-center text-white lg:text-left">
            <h2 className="text-2xl font-extrabold lg:mb-3 lg:text-5xl">
              Publica tu propiedad en
            </h2>
            <DialogBox tint="teal">
              <span className="text-3xl font-extrabold lg:text-6xl">
                arriendo o venta
              </span>
            </DialogBox>
            <p className="mb-32 mt-6 text-xl font-medium leading-5 lg:mb-4 lg:text-4xl">
              Encontramos las personas indicadas <br /> para tu inmueble en menos de 60 días.
            </p>
            <Button
              href="#dejanos-tus-datos"
              tint="alternative-lime"
              className="!inline-flex !justify-items-start !text-lg"
            >
              Empezar ahora
            </Button>
          </div>
        </Container>
      </Banner>
    </div>
  );
}
