import { Description, Title } from "@radix-ui/react-dialog";

export default [
  {
    id: "info-item-1",
    label: "1",
    title: "Información de inmueble",
    description: "Descripción, ubicación, detalles y características, entre otros."
  },
  {
    id: "info-item-2",
    label: "2",
    title: "Datos del propietario",
    description: "Los datos básicos del propietario del inmueble."
  },
  {
    id: "info-item-3",
    label: "3",
    title: "Fotografías",
    description: "Recuerda tener las fotografías del inmueble y aumentar la visibilidad."
  }
]
