import React from 'react';
import clsx from "clsx";

import "./styles.css";

interface IconCardProps {
  icon?: string;
  children?: React.ReactNode;
  featured?: boolean;

  className?: string;
}

const IconCard: React.FC<IconCardProps> = ({
  icon = null,
  children,
  featured = false,

  className,
  ...props
}) => {
  const dynamicClasses = {
    [`s2-icon-card--featured`]: featured,
  };

  return (
    <div className={clsx("s2-icon-card", dynamicClasses, className)} {...props}>
      {featured && icon && (
        <div className="s2-icon-card__icon">
          <img src={icon} />
        </div>
      )}
      <div className="s2-icon-card__content">{children}</div>
    </div>
  );
}

export default IconCard;
