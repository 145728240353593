import React from "react";
import { cn } from "@coninsa-s2/utils";

import "./styles.css";

function Section({ layout, tint, ptop, className, ...props }) {
  const dynamicClasses = {
    [`s2-section--${tint}`]: tint,
    [`s2-section--${layout}`]: layout,
    [`s2-section--${ptop}`]: ptop,
  };

  return (
    <section className={cn("s2-section", dynamicClasses, className)} {...props}>
      {props.children}
    </section>
  );
}

function SectionHeadline(props) {
  return <div className="s2-section__headline">{props.children}</div>;
}

function SectionContent(props) {
  return <div className="s2-section__content">{props.children}</div>;
}

Section.Content = SectionContent;
Section.Headline = SectionHeadline;

export default Section;
